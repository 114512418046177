@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
    /* font-family: 'Arial Bold', sans-serif; */
    font-family: 'Montserrat', sans-serif;
}

body{
    background: rgb(238, 240, 248);
    /* background: linear-gradient(180deg, rgba(225,232,238,1) 0%, rgba(208,206,233,0.1632003143054097) 75%); */
}

.primaire
{
    background:#153f68;
}

.secondaire{
    background: #255e8b;
}

.accent{
    background-color: #7DAEB4;
}

.accent-clair{
    background-color: #d3b71a;
}

.accent-fonce{
    background-color: #89857D;
}

.texte-primaire
{
    color:#153f68;
}

.texte-secondaire{
    color: #d3b71a;
}

.grand-texte{
    font-size: 100px;
}

.moyen-texte{
    font-size: 25px;
}

.bg-contact{
    background-image: url(img/facade.jpg);
}

.bg-faq{
    background-image: url('img/img-faq-2.jpg');
}

.bg-recherche{
    background-image: url('img/bg-home.jpg');
}

.parallax{
    
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    height: 350px;    
}

.titre{
    font-weight:800;
    font-size: 4rem;
}

nav li{
    font-weight: 700;
}

.politique p, .politique li{
    font-size: 23px;
    text-align: justify;
}

.politique .intro{
    font-size: 27px;
    text-align: justify;
    font-weight: 500;
}

.bg-home{
    height: 350px;
    background-image: url('img/bg-waves.png');
    /* background: rgb(29,88,117);
    background: radial-gradient(circle, rgba(29,88,117,1) 0%, rgba(39,100,131,1) 100%); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: white;
}

.bg-home-overlay{
    position: relative;
    text-align: center;

}

.image-livre{
    height: 165px;
}


/* Scroll  */
.sidescroll{
    display: flex;
    line-height: 1;
    max-width: 100%;
    overflow-x: scroll;
}

.sidescroll::-webkit-scrollbar{
    display: none;
}

.left{
    position: sticky;
    left: 0;
    z-index: 2;
    height: fit-content;
    width: fit-content;
    align-self: center;
}

.right{
    position: sticky;
    right: 0;
    z-index: 2;
    height: fit-content;
    width: fit-content;
    align-self: center;
}

.arrow {
    /*border-radius: 50px;*/
    padding: 3px;
    background-color:#153f68;
    border: none;
    color:white;
}

.img-hover{
    cursor: pointer;
}

.texte-justufie{
    text-align: justify;
}



/***********MEDIA QUERIES**********/
@media all and (max-width: 450px){
    .titre{
        font-size: 3rem;
    }

    .image-livre{
        height: 140px;
    }
}

@media screen and (min-width: 1024px){
    .titre-livre{
        font-size: 30px;
    }

    .hauteur-img{
        height: 230px;
    }
}